program TestCloverConnect;

uses
  Vcl.Forms,
  WEBLib.Forms,
  View.iFrame in 'View.iFrame.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TiFrameView, iFrameView);
  Application.Run;
end.
