Unit View.iFrame;

Interface

Uses
  System.SysUtils,
  System.Classes,

  JS,

  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WEBLib.JSON,
  Vcl.Controls,
  WEBLib.WebCtrls,
  WEBLib.StdCtrls,
  Vcl.StdCtrls, WEBLib.REST;

Type
  // ITokenReceiver = interface(IUnknown)
  // ['{9D6A7A73-BF65-4C18-8A8C-0D0CFC2346C8}']
  // procedure OnTokenReceived(const token: WideString); safecall;
  // end;


  TiFrameView = Class(TForm)
    iFrame: TBrowserControl;
    WebButton1: TButton;
    rbNone: TRadioButton;
    cbCVV: TCheckBox;
    cbMonthname: TCheckBox;
    cbExpiry: TCheckBox;
    cbCardTyping: TCheckBox;
    WebLabel1: TLabel;
    ebAmount: TEdit;
    lblToken: TLabel;
    url: TLabel;
    lblError: TLabel;
    WebButton2: TButton;
    PayRequest: THttpRequest;
    WebEdit1: TEdit;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    response: TLabel;
    WebEdit2: TEdit;
    WebEdit3: TEdit;
    Procedure WebFormShow(Sender: TObject);
    Procedure iFrameLoad(Sender: TObject; AEvent: TJSEvent);
    Procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure ebAmountChange(Sender: TObject);
    procedure PayRequestResponse(Sender: TObject; AResponse: string);
    Private
      Procedure Connect(Sender: TObject);
      Procedure OnTokenReceived(Const token: WideString);
    procedure HandleToken(const token: string);
    procedure ReceiveMessage(Event: TJSMessageEvent);
    function createcss: string;
    function URLEncode(const AStr: string): string;
    function IsURISafeChar(C: Char): Boolean;
    function CreatePayLoad(Token: String; Amount: string; Currency: String): String;
      // function WebBrowserWindowExternalCall(const aMethodName: string; const aParameters: TJSValueDynArray): TJSValue;
      { Private declarations }

    Private
      FToken : String;
      FAmount : String;
    Public
      { Public declarations }

  protected procedure LoadDFMValues; override; End;

Var
  iFrameView: TiFrameView;

const
  cssdot = '%2';
  cssleftbracket = '%7B';
  cssrightbracker = '%3B';
  csscolon = '%3A';
  cssspace = '%20';



Const
  /// cloverpath = 'https://isv-uat.cardconnect.com/cardconnect/rest/itoke/ajax-tokenizer.html';
  // cloverpath = 'fts-uat.cardconnect.com/itoke/ajax-tokenizer.html'                 ;
  cloverpath = 'https://isv-uat.cardconnect.com/itoke/ajax-tokenizer.html';

Implementation

{$R *.dfm}

function TiFrameView.IsURISafeChar(C: Char): Boolean;
begin
  Result := ((C >= 'A') and (C <= 'Z')) or
            ((C >= 'a') and (C <= 'z')) or
            ((C >= '0') and (C <= '9')) or
            (C = '-') or (C = '_') or (C = '.') or (C = '~');
end;

function TiFrameView.URLEncode(const AStr: string): string;
const
  HexChars: array[0..15] of Char = '0123456789ABCDEF';
var
  i: Integer;
  C: Char;
  SB: TStringBuilder;
begin
  SB := TStringBuilder.Create;
  try
    for i := 1 to Length(AStr) do
    begin
      C := AStr[i];
      if IsURISafeChar(C) then
        SB.Append(C)
      else
      begin
        SB.Append('%');
        SB.Append(HexChars[Ord(C) shr 4]);
        SB.Append(HexChars[Ord(C) and $0F]);
      end;
    end;
    Result := SB.ToString;
  finally
    SB.Free;
  end;
end;

Procedure TiFrameView.Connect(Sender: TObject);
Begin
  iFrame.URL := cloverpath;
End;

 Procedure TiFrameView.iFrameLoad(Sender: TObject; AEvent: TJSEvent);

Begin
 TJSWindow(Window).addEventListener('message', @ReceiveMessage);

End;

procedure TiFrameView.ReceiveMessage(Event: TJSMessageEvent);
var
  ValidationError : String;
  Data: TJSObject;
  JSONMessage: string;
  JSONObject: TJSONObject;
  JSONValue: TJSONValue;
begin
  if Assigned(Event) then
    begin
      if (Event.data <> nil) then
        begin
          JSONMessage := string(Event.data);
          JSONObject := TJSONObject.ParseJSONValue(JSONMessage) as TJSONObject;
           if JSONObject <> nil then
              begin
                JSONValue := JSONObject.GetValue('message');
                if JSONValue <> nil then
                  FToken := JSONValue.Value;
              end;
            HandleToken(FToken) ;
        end
      else
        begin
          Data := TJSObject(Event.data);
         if Data.hasOwnProperty('validationError')  then
          begin
            ValidationError := string(JS.toString(Data['validationError']));
            lblError.caption := ValidationError;
          end;
        end;
    end;
end;


procedure TiFrameView.HandleToken(const token: string);
begin
  lblToken.caption := token;

  // Process the token as needed, e.g., sending it to a server
end;

// asm
// window.addEventListener('message', function(event) {
// var token = event.data;
// HandleToken(token);
// }, false);
// end;
// function TiFrameView.WebBrowserWindowExternalCall(const aMethodName: string; const aParameters: TJSValueDynArray): TJSValue;
// begin
/// /  if aMethodName = 'OnTokenReceived' then
/// /  begin
/// /    OnTokenReceived(aParameters[0].toString);
/// /  end;
/// /  Result := JSValue(nil);
// end;

Procedure TiFrameView.OnTokenReceived(Const token: WideString);
Begin
  ShowMessage('Token received: ' + token);
End;

procedure TiFrameView.PayRequestResponse(Sender: TObject; AResponse: string);
begin
  response.Caption := AResponse;
end;

function TiFrameView.createcss:string;
begin
    result := '&css=input{width:200px; height:25px;border-radius:5px;}';

    result := URLEncode(result);
end;

Procedure TiFrameView.WebButton1Click(Sender: TObject);
Var
  custompath: String;
  seperator : String;
Begin
  custompath     := '';
  seperator      := '?';
  If cbCVV.Checked Then
    Begin
      custompath := custompath + seperator + 'usecvv=true';
      seperator  := '&';
    End;
  If cbExpiry.Checked Then
    Begin
      custompath := custompath + seperator + 'useexpiry=true';
      seperator  := '&';
    End;
  If cbMonthname.Checked Then
    Begin
      custompath := custompath + seperator + 'usemonthnames=true';
      seperator  := '&';
    End;

  If cbCardTyping.Checked Then
    Begin
      custompath := custompath + seperator + 'sendcardtypingevent=true';
      seperator  := '&';
    End;

  custompath := custompath + '&autofocus=true&inactivityto=1000&invalidcreditcardevent=true&formatinput=true';
  custompath     := cloverpath + custompath  ;//+ createcss;
  url.caption :=  custompath;

  iFrame.Navigate(custompath);
End;


//https://isv-uat.cardconnect.com/cardconnect/rest
//dGVzdGluZzp0ZXN0aW5nMTIz

function TiFrameView.CreatePayLoad(Token : String; Amount : string; Currency: String)  : String;
begin

  result := '{"merchid":"888800000018", "account": "'+ Token +  '", "amount":' + Amount + ', "currency": "' +Currency +'"}';
  lblError.Caption := result;
end;

procedure TiFrameView.ebAmountChange(Sender: TObject);
begin
 FAmount := ebAmount.Text;
end;

procedure TiFrameView.WebButton2Click(Sender: TObject);
begin
      PayRequest.PostData := CreatePayLoad(FToken, ebAmount.Text  , 'USD');
      PayRequest.execute;

end;

Procedure TiFrameView.WebFormShow(Sender: TObject);
Begin
  // iFrame.Navigate(cloverpath);
End;

procedure TiFrameView.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  lblToken := TLabel.Create(Self);
  url := TLabel.Create(Self);
  lblError := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  response := TLabel.Create(Self);
  iFrame := TBrowserControl.Create(Self);
  WebButton1 := TButton.Create(Self);
  rbNone := TRadioButton.Create(Self);
  cbCVV := TCheckBox.Create(Self);
  cbMonthname := TCheckBox.Create(Self);
  cbExpiry := TCheckBox.Create(Self);
  cbCardTyping := TCheckBox.Create(Self);
  ebAmount := TEdit.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebEdit3 := TEdit.Create(Self);
  PayRequest := THttpRequest.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  lblToken.BeforeLoadDFMValues;
  url.BeforeLoadDFMValues;
  lblError.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  response.BeforeLoadDFMValues;
  iFrame.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  rbNone.BeforeLoadDFMValues;
  cbCVV.BeforeLoadDFMValues;
  cbMonthname.BeforeLoadDFMValues;
  cbExpiry.BeforeLoadDFMValues;
  cbCardTyping.BeforeLoadDFMValues;
  ebAmount.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebEdit3.BeforeLoadDFMValues;
  PayRequest.BeforeLoadDFMValues;
  try
    Name := 'iFrameView';
    Width := 1655;
    Height := 908;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 209;
    WebLabel1.Top := 510;
    WebLabel1.Width := 30;
    WebLabel1.Height := 18;
    WebLabel1.Caption := 'Price';
    WebLabel1.ElementFont := efCSS;
    WebLabel1.HeightStyle := ssAuto;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    lblToken.SetParentComponent(Self);
    lblToken.Name := 'lblToken';
    lblToken.Left := 211;
    lblToken.Top := 569;
    lblToken.Width := 10;
    lblToken.Height := 18;
    lblToken.Caption := '--';
    lblToken.ElementFont := efCSS;
    lblToken.HeightStyle := ssAuto;
    lblToken.HeightPercent := 100.000000000000000000;
    lblToken.WidthPercent := 100.000000000000000000;
    url.SetParentComponent(Self);
    url.Name := 'url';
    url.Left := 214;
    url.Top := 619;
    url.Width := 10;
    url.Height := 18;
    url.Caption := '--';
    url.ElementFont := efCSS;
    url.HeightStyle := ssAuto;
    url.HeightPercent := 100.000000000000000000;
    url.WidthPercent := 100.000000000000000000;
    lblError.SetParentComponent(Self);
    lblError.Name := 'lblError';
    lblError.Left := 214;
    lblError.Top := 671;
    lblError.Width := 10;
    lblError.Height := 18;
    lblError.Caption := '--';
    lblError.ElementFont := efCSS;
    lblError.HeightStyle := ssAuto;
    lblError.HeightPercent := 100.000000000000000000;
    lblError.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 165;
    WebLabel2.Top := 569;
    WebLabel2.Width := 41;
    WebLabel2.Height := 18;
    WebLabel2.Caption := 'Token';
    WebLabel2.ElementFont := efCSS;
    WebLabel2.HeightStyle := ssAuto;
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 176;
    WebLabel3.Top := 619;
    WebLabel3.Width := 17;
    WebLabel3.Height := 18;
    WebLabel3.Caption := 'Url';
    WebLabel3.ElementFont := efCSS;
    WebLabel3.HeightStyle := ssAuto;
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 172;
    WebLabel4.Top := 671;
    WebLabel4.Width := 27;
    WebLabel4.Height := 18;
    WebLabel4.Caption := 'Msg';
    WebLabel4.ElementFont := efCSS;
    WebLabel4.HeightStyle := ssAuto;
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 134;
    WebLabel5.Top := 722;
    WebLabel5.Width := 59;
    WebLabel5.Height := 18;
    WebLabel5.Caption := 'response';
    WebLabel5.ElementFont := efCSS;
    WebLabel5.HeightStyle := ssAuto;
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.WidthPercent := 100.000000000000000000;
    response.SetParentComponent(Self);
    response.Name := 'response';
    response.Left := 214;
    response.Top := 722;
    response.Width := 10;
    response.Height := 18;
    response.Caption := '--';
    response.ElementFont := efCSS;
    response.HeightStyle := ssAuto;
    response.HeightPercent := 100.000000000000000000;
    response.WidthPercent := 100.000000000000000000;
    iFrame.SetParentComponent(Self);
    iFrame.Name := 'iFrame';
    iFrame.Left := 809;
    iFrame.Top := 0;
    iFrame.Width := 846;
    iFrame.Height := 908;
    iFrame.HeightStyle := ssPercent;
    iFrame.Align := alRight;
    iFrame.BorderStyle := bsNone;
    iFrame.Sandbox := [];
    SetEvent(iFrame, Self, 'OnLoad', 'iFrameLoad');
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 211;
    WebButton1.Top := 447;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.Caption := 'Load';
    WebButton1.ChildOrder := 1;
    WebButton1.ElementClassName := 'btn btn-light';
    WebButton1.ElementFont := efCSS;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    rbNone.SetParentComponent(Self);
    rbNone.Name := 'rbNone';
    rbNone.Left := 211;
    rbNone.Top := 179;
    rbNone.Width := 113;
    rbNone.Height := 22;
    rbNone.Caption := 'Use None';
    rbNone.Checked := False;
    rbNone.ChildOrder := 2;
    rbNone.Color := clNone;
    rbNone.ElementClassName := 'custom-control custom-radio';
    rbNone.ElementButtonClassName := 'custom-control-input';
    rbNone.ElementLabelClassName := 'custom-control-label';
    rbNone.ElementFont := efCSS;
    rbNone.HeightStyle := ssAuto;
    rbNone.HeightPercent := 100.000000000000000000;
    rbNone.WidthPercent := 100.000000000000000000;
    cbCVV.SetParentComponent(Self);
    cbCVV.Name := 'cbCVV';
    cbCVV.Left := 211;
    cbCVV.Top := 234;
    cbCVV.Width := 113;
    cbCVV.Height := 22;
    cbCVV.Caption := 'Use CVV';
    cbCVV.Checked := True;
    cbCVV.ChildOrder := 3;
    cbCVV.ElementClassName := 'custom-control custom-checkbox';
    cbCVV.ElementButtonClassName := 'custom-control-input';
    cbCVV.ElementLabelClassName := 'custom-control-label';
    cbCVV.ElementFont := efCSS;
    cbCVV.HeightStyle := ssAuto;
    cbCVV.HeightPercent := 100.000000000000000000;
    cbCVV.State := cbChecked;
    cbCVV.WidthPercent := 100.000000000000000000;
    cbMonthname.SetParentComponent(Self);
    cbMonthname.Name := 'cbMonthname';
    cbMonthname.Left := 211;
    cbMonthname.Top := 354;
    cbMonthname.Width := 205;
    cbMonthname.Height := 22;
    cbMonthname.Caption := 'Use Month Names';
    cbMonthname.Checked := True;
    cbMonthname.ChildOrder := 3;
    cbMonthname.ElementClassName := 'custom-control custom-checkbox';
    cbMonthname.ElementButtonClassName := 'custom-control-input';
    cbMonthname.ElementLabelClassName := 'custom-control-label';
    cbMonthname.ElementFont := efCSS;
    cbMonthname.HeightStyle := ssAuto;
    cbMonthname.HeightPercent := 100.000000000000000000;
    cbMonthname.State := cbChecked;
    cbMonthname.WidthPercent := 100.000000000000000000;
    cbExpiry.SetParentComponent(Self);
    cbExpiry.Name := 'cbExpiry';
    cbExpiry.Left := 211;
    cbExpiry.Top := 294;
    cbExpiry.Width := 113;
    cbExpiry.Height := 22;
    cbExpiry.Caption := 'use Expiry';
    cbExpiry.Checked := True;
    cbExpiry.ChildOrder := 3;
    cbExpiry.ElementClassName := 'custom-control custom-checkbox';
    cbExpiry.ElementButtonClassName := 'custom-control-input';
    cbExpiry.ElementLabelClassName := 'custom-control-label';
    cbExpiry.ElementFont := efCSS;
    cbExpiry.HeightStyle := ssAuto;
    cbExpiry.HeightPercent := 100.000000000000000000;
    cbExpiry.State := cbChecked;
    cbExpiry.WidthPercent := 100.000000000000000000;
    cbCardTyping.SetParentComponent(Self);
    cbCardTyping.Name := 'cbCardTyping';
    cbCardTyping.Left := 214;
    cbCardTyping.Top := 410;
    cbCardTyping.Width := 205;
    cbCardTyping.Height := 22;
    cbCardTyping.Caption := 'Use Card typing';
    cbCardTyping.Checked := True;
    cbCardTyping.ChildOrder := 3;
    cbCardTyping.ElementClassName := 'custom-control custom-checkbox';
    cbCardTyping.ElementButtonClassName := 'custom-control-input';
    cbCardTyping.ElementLabelClassName := 'custom-control-label';
    cbCardTyping.ElementFont := efCSS;
    cbCardTyping.HeightStyle := ssAuto;
    cbCardTyping.HeightPercent := 100.000000000000000000;
    cbCardTyping.State := cbChecked;
    cbCardTyping.WidthPercent := 100.000000000000000000;
    ebAmount.SetParentComponent(Self);
    ebAmount.Name := 'ebAmount';
    ebAmount.Left := 280;
    ebAmount.Top := 510;
    ebAmount.Width := 121;
    ebAmount.Height := 22;
    ebAmount.ChildOrder := 8;
    ebAmount.EditType := weNumeric;
    ebAmount.ElementClassName := 'form-control';
    ebAmount.ElementFont := efCSS;
    ebAmount.HeightStyle := ssAuto;
    ebAmount.HeightPercent := 100.000000000000000000;
    ebAmount.Text := '100.00';
    ebAmount.WidthPercent := 100.000000000000000000;
    SetEvent(ebAmount, Self, 'OnChange', 'ebAmountChange');
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 425;
    WebButton2.Top := 508;
    WebButton2.Width := 96;
    WebButton2.Height := 25;
    WebButton2.Caption := 'Pay';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'btn btn-light';
    WebButton2.ElementFont := efCSS;
    WebButton2.HeightStyle := ssAuto;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 151;
    WebEdit1.Top := 84;
    WebEdit1.Width := 246;
    WebEdit1.Height := 22;
    WebEdit1.ChildOrder := 13;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.ElementFont := efCSS;
    WebEdit1.HeightStyle := ssAuto;
    WebEdit1.HeightPercent := 100.000000000000000000;
    WebEdit1.Text := '4387751111111038';
    WebEdit1.WidthPercent := 100.000000000000000000;
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 151;
    WebEdit2.Top := 116;
    WebEdit2.Width := 246;
    WebEdit2.Height := 22;
    WebEdit2.ChildOrder := 13;
    WebEdit2.ElementClassName := 'form-control';
    WebEdit2.ElementFont := efCSS;
    WebEdit2.HeightStyle := ssAuto;
    WebEdit2.HeightPercent := 100.000000000000000000;
    WebEdit2.Text := '4387751111111046';
    WebEdit2.WidthPercent := 100.000000000000000000;
    WebEdit3.SetParentComponent(Self);
    WebEdit3.Name := 'WebEdit3';
    WebEdit3.Left := 151;
    WebEdit3.Top := 53;
    WebEdit3.Width := 246;
    WebEdit3.Height := 22;
    WebEdit3.ChildOrder := 13;
    WebEdit3.ElementClassName := 'form-control';
    WebEdit3.ElementFont := efCSS;
    WebEdit3.HeightStyle := ssAuto;
    WebEdit3.HeightPercent := 100.000000000000000000;
    WebEdit3.Text := '4788250000121443';
    WebEdit3.WidthPercent := 100.000000000000000000;
    PayRequest.SetParentComponent(Self);
    PayRequest.Name := 'PayRequest';
    PayRequest.Command := httpPOST;
    PayRequest.Headers.BeginUpdate;
    try
      PayRequest.Headers.Clear;
      PayRequest.Headers.Add('Authorization:Basic dGVzdGluZzp0ZXN0aW5nMTIz');
      PayRequest.Headers.Add('Content-Type:application/json');
    finally
      PayRequest.Headers.EndUpdate;
    end;
    PayRequest.URL := 'https://clover.32yo.com/api/cardconnect';
    SetEvent(PayRequest, Self, 'OnResponse', 'PayRequestResponse');
    PayRequest.Left := 464;
    PayRequest.Top := 763;
  finally
    WebLabel1.AfterLoadDFMValues;
    lblToken.AfterLoadDFMValues;
    url.AfterLoadDFMValues;
    lblError.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    response.AfterLoadDFMValues;
    iFrame.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    rbNone.AfterLoadDFMValues;
    cbCVV.AfterLoadDFMValues;
    cbMonthname.AfterLoadDFMValues;
    cbExpiry.AfterLoadDFMValues;
    cbCardTyping.AfterLoadDFMValues;
    ebAmount.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebEdit3.AfterLoadDFMValues;
    PayRequest.AfterLoadDFMValues;
  end;
end;

End.
